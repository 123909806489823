import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import editWhiteIcon from '../../assets/edit-white.svg';
import deleteWhiteIcon from '../../assets/delete-white.svg';
import OutcomesTable from "../../components/Outcomes/OutcomesTable";
import InitiativeInteractionTable from "../../components/Initiatives/InitiativeInteractionTable";
import FiscalYear from "../../components/Filters/FiscalYear";
import InteractionType from "../../components/Interactions/InteractionType";
import AssistanceType from "../../components/Interactions/AssistanceType";
import BulkActionBtn from "../../components/Interactions/BulkActionBtn";
import UploadInteractionsBtn from "../../components/Interactions/UploadInteractionsBtn";
import AddInteraction from "../../components/Interactions/AddInteractionButton";
import NewOutcomeButton from "../../components/Outcomes/NewOutcomeButton";
import { InitiativeData, InteractionData } from "../../services/types";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import { useAdminContext } from "../../context/AdminContext";
import InteractionDataContext from "../../context/InteractionDataContext";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import useFilteredData from "../../hooks/useFilteredData";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import NoResultsMessage from "../../components/Filters/NoResultsMessage";
import BackButtonHeader from "../../components/Navigation/BackButtonHeader";
import OutcomesDataContext from "../../context/OutcomeDataContext";


function InitiativeDetail() {
    // Context and hook usage
    const { isAdmin } = useAdminContext();
    const { initiatives } = useContext(InitiativeDataContext);
    const { outcomes } = useContext(OutcomesDataContext);
    const { interactions, setInteractionsYear } = useContext(InteractionDataContext);

    // State variables
    const [initiative, setInitiative] = useState<string>('');
    const [initiativeData, setInitiativeData] = useState<InitiativeData>();
    const [data, setData] = useState<InteractionData[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [year, setYear] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [interactionType, setInteractionType] = useState<string>('');
    const [assistanceType, setAssistanceType] = useState<string>('');

    // Calculate available years for the fiscal year filter
    const currFisYear = calculateFiscalYear(year); 
    const availableYears = calculateAvailableYears();

    // Filtered interactions based on various criteria
    const { filteredInteractions } = useFilteredData(
        undefined, undefined, undefined, interactions,
        undefined, sortType, year, undefined, undefined, interactionType,
        undefined, assistanceType
    );

    // Route and state management
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // current interactions for the initiative
    const currInteractions = data.filter(item => Number(item.initiative_id) === Number(id));

    // Calculate the outcomes based on id
    const currOutcomes = outcomes.filter(outcome => Number(outcome.initiative_id) === Number(id));

    // Set interactionsYear as 'all' for default year selection
    useEffect(() => {
        setInteractionsYear('all');
    }, []);

    // Fetch and set data when filteredInteractions or id changes
    useDeepCompareEffect(() => {
        setData(filteredInteractions);
    }, [interactions, filteredInteractions, id]);

    // Set the initiative state based on rowData.Initiative when the component mounts
    useEffect(() => {

        const currentInitiative = initiatives.find(initiative => initiative.id === Number(id));

        if (currentInitiative) {
            setInitiativeData(currentInitiative);
            setInitiative(currentInitiative.name)
        }
    }, [initiatives, id]);

    // Handle filtering, and navigation
    const filterByYear = (year: string) => {
        setInteractionsYear(year);
        setYear(year);
    }
    const filterByInteractionType = (interactionType: string) => setInteractionType(interactionType);
    const filterByAssistanceType = (assistanceType: string) => setAssistanceType(assistanceType);

    // Handler for navigating back to Initiatives page
    const navigateBack = () => navigate('/commerce-initiatives');

    // Handler for edit click
    const handleEditClick = (id: string | undefined) => navigate(`/commerce-initiatives/edit/${id}`, { state: { initiativeData, from: window.location.pathname }});

    // Handler for delete click
    const handleDeleteClick = async () => {
        try {
            navigate(`/commerce-initiatives/delete/${initiativeData?.id}`, { state: { rowData: initiativeData } });
        } catch (error) {
            console.error('Error handling delete click:', error);
        }
    };

    // Handler for bulk actions
    const handleBulkAction = () => {
        console.log("Performing bulk action on selected rows:", selectedRows);
    };

    const handleRemoveFilters = () => {
        setInitiative('');
        setInteractionsYear(currFisYear.toString());
        setYear(currFisYear.toString());
        setSortType('');
        setInteractionType('');
        setAssistanceType('');
    };

    return (
        <>
            <BackButtonHeader navigateBack={navigateBack} name="Initiatives"/>
            <div className="p-4 m-2">
                <div className="mb-8 pb-4 border border-solid border-titanium-white rounded-md w-full">
                    <div className='relative flex items-center border-b border-solid border-gotham-gray bg-gotham-gray rounded-t-md mb-4'>
                        <h2 className="text-white text-sm font-semibold mt-3 mb-3 ml-7">{initiativeData?.name}</h2>
                        {isAdmin || (!isAdmin && !initiativeData?.admin_managed) ? (
                        <div className="absolute right-5 flex items-center">
                            <button onClick={() => handleEditClick(id)} className="mr-1 flex items-center">
                                <img className="border border-solid border-gotham-gray h-6 w-6 p-1 rounded-md" src={editWhiteIcon} alt="Edit" />
                            </button>
                            <button onClick={handleDeleteClick} className="flex items-center">
                                <img className="border border-solid border-gotham-gray h-6 w-6 p-1 rounded-md" src={deleteWhiteIcon} alt="Delete" />
                            </button>
                        </div>) : null
                        }
                    </div>
                    <div className='flex flex-wrap sm:flex-nowrap ml-3 w-full'>
                        {/* Initiative description */}
                        <div className="flex flex-col items-start mx-4 my-2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Description:
                            </div>
                            <div className="text-gotham-gray text-sm sm:text-wrap max-w-2xl mr-2">
                                {initiativeData?.description}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap ml-3">
                        {/* Initiative Type */}
                        {/* <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Initiative Type:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData?.initiative_type?.name}
                            </div>
                        </div>
                        */}
                        {/* Initiative Status */}
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Status:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData && initiativeData.status.charAt(0).toUpperCase() + initiativeData.status.slice(1).toLowerCase()}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap ml-3">
                        {/* Total Interactions */}
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Total Interactions:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData?.total_interactions}
                            </div>
                        </div>
                        {/* Total Interactions */}
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Year:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData?.year}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap ml-3">
                        {/* Industry */}
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Industry:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData?.industry_detail?.industry?.name || 'All Industries'}
                            </div>
                        </div>
                        {/* Sub-Industry */}
                        {initiativeData?.industry_detail?.industry?.name && 
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                            Sub-Industry:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                            {initiativeData?.industry_detail?.sub_industry?.name || 'N/A'}
                            </div>
                        </div>   
                        }
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap ml-3">
                        {/* Service Area */}
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-gotham-gray text-sm font-semibold">
                                Service Area:
                            </div>
                            <div className="text-gotham-gray text-sm flex items-center w-full">
                                {initiativeData?.all_area ? 'Full ADO Area' : 'Selected Area within ADO'}
                            </div>
                        </div>
                        {/* Area within ADO */}
                        {!initiativeData?.all_area &&
                            <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                                <div className="text-gotham-gray text-sm font-semibold">
                                    Area within ADO:
                                </div>
                                <div className="text-gotham-gray text-sm flex items-center w-full">
                                    {!initiativeData?.all_area && initiativeData?.locations && initiativeData?.locations.length > 0 && (
                                        initiativeData?.locations.map((location: any) => location.location).join(', ')
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="mb-8 border border-solid border-titanium-white rounded-md w-full">
                    <div className="flex items-center py-3 bg-white text-white rounded-t-md">
                        <h2 className="text-gotham-gray text-sm font-semibold ml-7 w-full">Outcomes</h2>
                        <NewOutcomeButton id={id} from={window.location.pathname}/>
                    </div>
                    {currOutcomes.length > 0 ?
                    <OutcomesTable id={id} partnerId={initiativeData?.partner_id} initiativeId={initiativeData?.id} from={window.location.pathname}/>
                    :    
                    <NoResultsMessage 
                        itemName="outcomes"
                        hasFilters={false}
                        onRemoveFilters={handleRemoveFilters}
                    />
                    }
                </div>
                {/* Only show Interactions for ADO view */}
                {!isAdmin && (
                <div className="mb-8 border border-solid border-titanium-white rounded-md w-full">
                    <div className="flex flex-wrap items-center my-3 px-2">
                        <h2 className="text-gotham-gray text-sm font-semibold ml-5 w-24">Interactions</h2>
                        <div className="ml-auto flex flex-wrap items-center">
                            <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                            <InteractionType filterByInteractionType={filterByInteractionType} currInteractionType={interactionType}/>
                            <AssistanceType filterByAssistanceType={filterByAssistanceType} currAssistanceType={assistanceType}/>
                            <AddInteraction id={id}/>

                            <BulkActionBtn disabled={selectedRows.length === 0} handleBulkAction={handleBulkAction} selectedRows={selectedRows}/>
                            <UploadInteractionsBtn id={id} initiative={initiative} initiativeData={initiativeData} isDefault={false}/>
                        </div>
                    </div>
                    {currInteractions.length > 0 ?
                    <InitiativeInteractionTable data={data} initiativeId={id} handleSelectRows={setSelectedRows}/>
                    :
                    <NoResultsMessage 
                        itemName="interactions"
                        hasFilters={true}
                        onRemoveFilters={handleRemoveFilters}
                    />
                    }
                </div>
                )}
            </div>
        </>
    );
}

export default InitiativeDetail;