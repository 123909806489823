import LandingCard from "../../components/Landing/LandingCard";
import targetsSvg from "../../assets/target.svg";
import initiativesSvg from "../../assets/initiatives.svg";
import projectsSvg from "../../assets/projects.svg";
import interactionsSvg from "../../assets/interactions.svg";
import summarySvg from "../../assets/summary.svg";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import { useAdminContext } from "../../context/AdminContext";
import Expand from "../../components/Navigation/Expand";
import { useIframeContext } from "../../context/IframeContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import LogoutButton from "../../components/Auth/Logout";
import { calculateFiscalYear, calculateInteractionTotals } from "../../utils/helpers";
import { useFetchInteractionSummaryData } from "../../hooks/useFetchInteractionSummaryData";
import { useContext, useEffect, useState } from "react";
import AdoDataContext from "../../context/AdoDataContext";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import OutcomesDataContext from "../../context/OutcomeDataContext";
import InteractionDataContext from "../../context/InteractionDataContext";
import TargetsDataContext from "../../context/TargetDataContext";
import useFilteredData from "../../hooks/useFilteredData";
import InteractionSummary from "../../components/Landing/InteractionSummary";
import AdminViewSelect from "../../components/Landing/AdminViewSelect";

function Landing () {
    const { isAdmin } = useAdminContext();
    const { partnerName, admin } = useOrganizationContext();
    const { isIframe } = useIframeContext();
    const { partnerData } = useContext(AdoDataContext);

    const { initiatives } = useContext(InitiativeDataContext);
    const { outcomes } = useContext(OutcomesDataContext);
    const { interactions } = useContext(InteractionDataContext);
    const { targets } = useContext(TargetsDataContext);

    const [ado, setAdo] = useState<string>('');
    const [partnerId, setPartnerId] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [year, setYear] = useState<string>('');

    console.log('org id:', partnerId, 'org name:', partnerName);
    const currFisYear = calculateFiscalYear(year); 

    const { totalTargets } = useFilteredData(targets, outcomes, initiatives, interactions, undefined, sortType, year, undefined, ado, undefined, undefined);

    const { interactionSummary } = useFetchInteractionSummaryData(year, partnerId?.toString() || '');
    const totalInteractions = calculateInteractionTotals(interactionSummary);

    useEffect(() => {
        setSortType('alphabetical');
        setYear(currFisYear.toString());
    }, []);

    // Effect to set partnerId whenever ado changes
    useEffect(() => {
        if (isAdmin) {
            const partner = partnerData.find(partner => partner.name === ado);
            if (partner) {
                setPartnerId(partner.id?.toString());
            } else {
                // Set partnerId based on partnerName if no partner is found
                if (partnerName === 'Admin') {
                    setPartnerId('-1');
                } else if (partnerName === 'CAI-Admin') {
                    setPartnerId('-2');
                } else {
                    setPartnerId('');
                }
            }
        } else {
            setAdo(partnerName);
            const partner = partnerData.find(partner => partner.name === partnerName);
            if (partner) {
                setPartnerId(partner.id?.toString());
            } else {
                setPartnerId('');
            }
        }
    }, [ado, partnerData, partnerName, isAdmin]);

    // Filtering for admin view select
    const filterByAdo = (partner_id: string) => setAdo(partner_id);

    // Show error for current login doesn't have partner data
    const noPartnerFound = partnerName === 'no_partner_found';

    if (noPartnerFound) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center">
                    <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
                    <p className="text-lg text-gray-700">
                        No data available for the current login.
                    </p>
                </div>
                <div className="mt-4">
                    {!isIframe && 
                        <>
                            <div className="flex justify-end items-center mr-2">
                                <LogoutButton/>
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }
    
    return (
        <>
            <ReportingDropdown/>
            <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
                
                
                <div className="flex items-start justify-between my-2">
                    <InteractionSummary totalInteractions={totalInteractions} totalTargets={totalTargets} />
                    <Expand/>
                </div>
                
                {isIframe &&
                    <div className="flex justify-between items-center">
                        <h2 className="font-inter font-semibold text-xl text-left text-gotham-gray ml-2 mt-4 mb-1">ADO Reporting</h2>  
                    </div>
                }

                <div className="flex justify-start items-start">
                    {!isIframe && 
                        <>
                            <div className="flex justify-end items-center mr-2">
                                <LogoutButton/>
                            </div>
                        </>
                    }
                    { admin &&
                        <div className="">
                            <AdminViewSelect currAdo={ado} filterByAdo={filterByAdo}/>
                        </div>
                    }
                </div>

                {/* Conditional rendering based on viewType */}
                {isAdmin ? (
                    <div className="flex flex-col flex-wrap xl:flex-row gap-4 p-2 w-full">
                        <LandingCard
                            header="Targets"
                            text="Create and manage program achievement targets for  business engagement activities."
                            svgUrl={targetsSvg}
                            linkTo="/commerce-targets"
                            height="h-36"
                        />
                        <LandingCard
                            header="Projects"
                            text={`Add Business Projects to report key metrics for project "wins".`}
                            svgUrl={projectsSvg}
                            linkTo="/commerce-projects"
                            height="h-36"
                        />
                        <LandingCard
                            header="Initiatives"
                            text="Add Initiatives to track interactions and projects for strategic campaigns and local initiatives."
                            svgUrl={initiativesSvg}
                            linkTo="/commerce-initiatives"
                            height="h-36"
                        />
                        <LandingCard
                            header="Summary Dashboard"
                            text="See summary data of interactions and download outcome reports by initiative."
                            svgUrl={summarySvg}
                            linkTo="/commerce-summary"
                            height="h-36"
                        />
                        {/* <LandingCard
                            header="Surveys"
                            text="Indicators related to creation, retention, transfer, and accumulation of wealth within a community."
                            svgUrl={surveySvg}
                            linkTo="/commerce-surveys"
                            height="h-36"
                        /> */}
                    </div>
                ) : (
                    <div className="flex flex-col flex-wrap xl:flex-row gap-4 p-2 w-full">
                        <LandingCard
                            header="Interactions"
                            text="Add Business Interactions individually or upload in bulk."
                            svgUrl={interactionsSvg}
                            linkTo="/commerce-interactions"
                            height="h-36"
                        />
                        <LandingCard
                            header="Projects"
                            text={`Add Business Projects to report key metrics for project "wins".`}
                            svgUrl={projectsSvg}
                            linkTo="/commerce-projects"
                            height="h-36"
                        />
                        <LandingCard
                            header="Initiatives"
                            text="Add Initiatives to track interactions and projects for strategic campaigns and local initiatives."
                            svgUrl={initiativesSvg}
                            linkTo="/commerce-initiatives"
                            height="h-36"
                        />
                        <LandingCard
                            header="Summary Dashboard"
                            text="See summary data of interactions and download outcome reports by initiative."
                            svgUrl={summarySvg}
                            linkTo="/commerce-summary"
                            height="h-36"
                        />
                        {/* <LandingCard
                            header="Surveys"
                            text="Indicators related to creation, retention, transfer, and accumulation of wealth within a community."
                            svgUrl={surveySvg}
                            linkTo="/commerce-surveys"
                            height="h-40"
                        /> */}
                        {/* Add ADO specific cards or components here */}
                    </div>
                )}
            </div>
        </>
    );
}

export default Landing ;