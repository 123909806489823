import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Select from 'react-select';
import TargetsDataContext from '../../context/TargetDataContext';
import { calculateAvailableYears } from '../../utils/helpers';
import { customStyles, customStylesXL } from '../../utils/customFormStyles';
import validator from 'validator';
import FormErrorModal from '../../components/Modals/FormErrorModal';
import AdoDataContext from '../../context/AdoDataContext';
import BackButtonHeader from '../../components/Navigation/BackButtonHeader';

function AddTarget() {
    const { targets, fetchTargets } = useContext(TargetsDataContext);
    const { partnerData, adoNames } = useContext(AdoDataContext);
    const [adoOptions, setAdoOptions] = useState<{ label: string; value: string }[]>([]);
    const [availableYears, setAvailableYears] = useState<{ label: string; value: string }[]>([]);
    const [ado, setAdo] = useState<{ label: string; value: string } | null>(null);
    const [year, setYear] = useState<{ label: string; value: string } | null>(null);
    const [bre, setBRE] = useState<number>(0);
    const [recruitment, setRecruitment] = useState<number>(0);
    const [startup, setStartup] = useState<number>(0);
    const [exportValue, setExportValue] = useState<number>(0);
    const [training, setTraining] = useState<number>(0);
    const [marketingPlan, setMarketingPlan] = useState<boolean>(false);
    const [marketingPlanReceived, setMarketingPlanReceived] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const navigate = useNavigate();
    const navigateBack = () => {
        fetchTargets();
        navigate('/commerce-targets');
    };

    useEffect(() => {
        setAdoOptions(adoNames);
        setAvailableYears(calculateAvailableYears().map((year) => ({ label: year, value: year })));
    }, []);

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<number>>) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numValue = value ? parseFloat(value) : 0; // Convert to number, default to 0 if empty
        setter(numValue);
    }

    const handleMarketingPlanChange = () => setMarketingPlan(prevState => !prevState);
    const handleMarketingPlanReceived = () => setMarketingPlanReceived(prevState => !prevState);

    const validateForm = () => {
        const validations = [
            { field: bre, validator: validator.isNumeric, message: 'Please enter a valid number for BRE.' },
            { field: recruitment, validator: validator.isNumeric, message: 'Please enter a valid number for Recruitment.' },
            { field: startup, validator: validator.isNumeric, message: 'Please enter a valid number for Startup.' },
            { field: exportValue, validator: validator.isNumeric, message: 'Please enter a valid number for Export.' },
            { field: training, validator: validator.isNumeric, message: 'Please enter a valid number for Training.' },
        ];
    
        for (let { field, validator, message } of validations) {
            if (field !== undefined && field !== null) {
                if (validator && typeof validator === 'function' && !validator(field.toString())) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                }
            } else {
                // If the field is undefined or null, it's also considered invalid
                setFormErrorMsg(message);
                setShowModal(true);
                return false;
            }
        }
        // form is valid
        return true; 
    };

    const handleSave = async () => {
        if (isSubmitted) return; // Prevent multiple submissions

        if (!validateForm()) {
            console.log('validation error, missing required form fields');
            return;
        }

        setError(null);

        const selectedAdo = ado ? ado.value.trim().toLowerCase() : '';
        const selectedYear = year ? year.value : '';

        // Find the partner data matching the selected ADO name
        const partner = partnerData.find(partner => partner.name.trim().toLowerCase() === selectedAdo);


        if (!partner) {
            setFormErrorMsg(`Partner ID for ${selectedAdo} not found.`);
            setShowModal(true);
            return;
        }

        const existingTarget = targets.find((target) => {
            console.log('target partner name:', target.partner_name.trim().toLowerCase());
            console.log('selected ado:', selectedAdo);
            console.log('years:', target.year, selectedYear);
            
            return target.partner_name.trim().toLowerCase() === selectedAdo && target.year === selectedYear
        });

        if (existingTarget) {
            setFormErrorMsg(`A target for ${selectedAdo} in ${selectedYear} already exists.`);
            setShowModal(true);
            return;
        }
        // set submission state to true
        setIsSubmitted(true);

        const newTarget = {
            external_id: "",
            year: selectedYear,
            bre: bre,
            recruitment: recruitment,
            startup: startup,
            export: exportValue,
            training: training,
            marketing_plan_required: marketingPlan,
            marketing_plan_received: marketingPlanReceived,
        };

        // Proceed with form submission
        try {
            const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/addtarget?partnerId=${partner.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newTarget),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to add target');
            }

            console.log('Data successfully sent to backend:', responseData);
            setAdo(null);
            setYear(null);
            setBRE(0);
            setRecruitment(0);
            setStartup(0);
            setExportValue(0);
            setTraining(0);
            setMarketingPlan(false);
            navigateBack();
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error sending data to backend:', error);
            setError('There was an issue saving the target. Please try again later.');
            setIsSubmitted(false);
        }
    };

    const handleCancel = () => {
        navigateBack();
    };

    return (
        <>
            <BackButtonHeader navigateBack={navigateBack} name='Targets'/>
            <div className="m-2 p-4">
                <div className="mb-8 border border-solid border-titanium-white rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Add Target Details</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="selectAdo" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-20">
                                ADO:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectAdo"
                                        value={ado}
                                        onChange={(selectedOption) => setAdo(selectedOption)}
                                        options={adoOptions}
                                        className="w-72"
                                        placeholder="Select ADO"
                                        styles={customStylesXL(isSubmitted, ado)}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !ado ? 'ADO is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>

                        <div className="ff-container">
                            <label htmlFor="selectYear" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-36">
                                Year:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectYear"
                                        value={year}
                                        onChange={(selectedOption) => setYear(selectedOption)}
                                        options={availableYears}
                                        className="w-72"
                                        placeholder="Select Year"
                                        styles={customStyles(isSubmitted, year)}
                                        isSearchable={false}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !year ? 'Year is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-8 border border-solid border-titanium-white rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Add Target Definitions</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="inputBRE" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-20">
                                BRE:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <input
                                        id="inputBRE"
                                        type="text"
                                        value={bre}
                                        placeholder='Add BRE'
                                        onChange={handleInputChange(setBRE)}
                                        className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !bre ? 'border-red-500' : 'border-titanium-white'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !bre ? 'BRE value is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>

                        <div className="ff-container">
                            <label htmlFor="inputRecruitment" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-36">
                                Recruitment:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-1/2'>
                                    <input
                                        id="inputRecruitment"
                                        type="text"
                                        value={recruitment}
                                        placeholder='Add Recruitment'
                                        onChange={handleInputChange(setRecruitment)}
                                        className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !recruitment ? 'border-red-500' : 'border-titanium-white'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !recruitment ? 'Recruitment value is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="inputStartup" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-20">
                                Startup:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputStartup"
                                    type="text"
                                    value={startup}
                                    placeholder='Add Startup'
                                    onChange={handleInputChange(setStartup)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !startup ? 'border-red-500' : 'border-titanium-white'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !startup ? 'Startup value is required.' : ''}`}</p>
                            </div>
                        </div>

                        <div className="ff-container">
                            <label htmlFor="inputExport" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-36">
                                Export:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputExport"
                                    type="text"
                                    value={exportValue}
                                    placeholder='Add Export'
                                    onChange={handleInputChange(setExportValue)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !exportValue ? 'border-red-500' : 'border-titanium-white'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !exportValue ? 'Export value is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="inputTraining" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-20">
                                Training:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputTraining"
                                    type="text"
                                    value={training}
                                    placeholder='Add Training'
                                    onChange={handleInputChange(setTraining)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !training ? 'border-red-500' : 'border-titanium-white'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !training ? 'Training value is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="inputMarketingPlan" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-10 font-semibold w-25">
                                Marketing Plan Required:
                            </label>
                            <button
                                id="inputMarketingPlan"
                                type="button"
                                onClick={handleMarketingPlanChange}
                                className={`w-14 h-7 mr-28 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${marketingPlan ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${marketingPlan ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                        </div>
                        <div className="ff-container">
                            <label htmlFor="inputMarketingPlanReceived" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-10 font-semibold w-25">
                                Marketing Plan Received:
                            </label>
                            <button
                                id="inputMarketingPlanReceived"
                                type="button"
                                onClick={handleMarketingPlanReceived}
                                className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${marketingPlanReceived ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${marketingPlanReceived ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                        </div>
                    </div>
                    <FormErrorModal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        message={formErrorMsg}
                    />
                    <div className='border-t border-solid border-titanium-white mt-5 mb-2'>
                        {/* Display the server error message if it exists */}
                        {error && (
                        <div className="text-red-500 text-sm mt-5 ml-7">
                            {error}
                        </div>
                        )}
                        <button
                            id="save-target"
                            onClick={handleSave}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-500  hover:bg-blue-400 hover:border-blue-500"
                        > Save
                        </button>
                        <button
                            id="cancel-target"
                            onClick={handleCancel}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                        > Cancel
                        </button>
                    </div>
                </div>            
            </div>
        </>
    );
}

export default AddTarget;