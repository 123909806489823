import { InteractionSummaryData } from "../services/types";

export const generatePageNumbers = (totalPages: number, currentPage: number): (number | string)[] => {
    let pageNumbers: (number | string)[] = [];

    // Case 1: Less than 5 pages
    if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        // Case 2: More than 5 pages
        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(startPage + 2, totalPages);

        // Add first page and ellipsis if needed
        pageNumbers.push(1);
        if (startPage > 2) {
            pageNumbers.push('...');
        }

        // Add intermediate pages
        for (let i = startPage + 1; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        // Add last page and ellipsis if needed
        if (endPage < totalPages - 1) {
            pageNumbers.push('...');
        }
        
        // Ensure the last page is always included
        if (endPage !== totalPages) {
            pageNumbers.push(totalPages);
        }
    }

    return pageNumbers;
};

// number formatting for visible numbers
export function addCommas(val: number | string) {
    if (val != undefined) {
        val = val.toString().replace(/,/g, ''); //remove existing commas first
        var valSplit = val.split('.'); //then separate decimals

        while (/(\d+)(\d{3})/.test(valSplit[0].toString())) {
            valSplit[0] = valSplit[0].toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }

        if (valSplit.length == 2) { //if there were decimals
            val = valSplit[0] + "." + valSplit[1]; //add decimals back
        } else {
            val = valSplit[0];
        }
    }

    return val;
}

// calculateAvailableYears - get current year and historial years for ADO data
export const calculateAvailableYears = (): string[] => {
    const currentYear = new Date().getFullYear();
    const futureYear = currentYear + 1;
    const startYear = 2022; // Set the starting year to 2022
    const yearsArray = Array.from({ length: futureYear - startYear + 1 }, (_, index) => (startYear + index).toString());
    return yearsArray;
};

export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let day = today.getDate().toString().padStart(2, '0');

    // Format the date as YYYYMMDD
    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
}

// get current quarter as a string for forms and api payload
export const getCurrentQuarter = () => {
    const currentMonth = new Date().getMonth();

    if (currentMonth < 3) {
        return 'Q1';
    } else if (currentMonth < 6) {
        return 'Q2';
    } else if (currentMonth < 9) {
        return 'Q3';
    } else {
        return 'Q4';
    }
}

export function calculateFiscalYear(selectedFY: number | string) {
    // If there's a selected fiscal year in the session state, return it
    if (selectedFY) {
        return selectedFY;
    }

    // Get the current month and year
    const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based, so add 1
    const currentYear = new Date().getFullYear();

    // Determine the fiscal year
    let fiscalYear;
    if (currentMonth >= 7) {
        fiscalYear = currentYear + 1;
    } else {
        fiscalYear = currentYear;
    }

    return fiscalYear;
}

export function findFiscalYear(dateString: string) {
    // Parse the date string into a Date object
    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[0], 10);
    // const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    

    // Determine the fiscal year based on the month
    let fiscalYear;
    if (month >= 7) {
        fiscalYear = year + 1;
    } else {
        fiscalYear = year;
    }
    return fiscalYear;
}

// used in useFilteredDate for calculating interaction fisyear
export const getFiscalYear = (date: Date, fyStartMonth: number = 7) => {
    const fiscalYearStart = new Date(date.getFullYear(), fyStartMonth - 1, 1); // Fiscal year start date
    if (date >= fiscalYearStart) {
        return date.getFullYear() + 1;
    }
    return date.getFullYear();
};

// Helper function to clean string values
export const cleanStringValue = (value: any): string => {
    if (typeof value === 'string') {
        // Remove any characters that are not digits or decimal points
        value = value.replace(/[^\d.-]/g, '').trim();
    }
    return value;
};

// Helper function to clean and validate numeric values for coordinates (lat/long)
export const validateAndCleanCoordinate = (value: any, min: number, max: number): string => {
    const num = Number(value);
    return !isNaN(num) && num >= min && num <= max ? num.toString() : '0';
};

// Trim whitespace and set string to lowercase for sanitization
export const sanitizeString = (str: string) => str.trim().toLowerCase();

// formatPhoneNumber - clean phone numbers with universal format (123) 456-7890
export const formatPhoneNumber = (input: string) => {
    // Regular expression to match the main phone number and extension
    const phoneRegex = /^(?:\+?1[-.\s]?)?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})(?:[\s,]*ext\.?\s*(\d+))?$/i;
    
    const match = input?.match(phoneRegex);
  
    if (!match) {
        console.log('Invalid phone number.')
        return null;
    }
  
    const areaCode = match[1];
    const centralOfficeCode = match[2];
    const lineNumber = match[3];
    const extension = match[4];
  
    // Format the phone number as XXX-XXX-XXXX
    let formattedNumber = `${areaCode}-${centralOfficeCode}-${lineNumber}`;
    
    // If there's an extension, append it
    if (extension) {
        formattedNumber += ` ext. ${extension}`;
    }
  
    return formattedNumber;
}

// Get business name in order of priority: legal name -> trade name
export const getName = (interaction: any) => interaction.business_legal_name || interaction.business_trade_name ||  '';
  
// calculateInteractionTotals -> generate totals for all interaction types
export function calculateInteractionTotals(interactionSummary: InteractionSummaryData | null) {
    const initialTotals = {
        bre: 0,
        recruitment: 0,
        training: 0,
        startup: 0,
        export: 0,
        marketing_plan_required: 0,
        marketing_plan_received: 0
    };

    if (!interactionSummary) return initialTotals;

    return interactionSummary.types.reduce((acc, type) => {
        switch (type.id) {
            case 1:
                acc.bre = parseInt(type.total_interactions);
                break;
            case 2:
                acc.recruitment = parseInt(type.total_interactions);
                break;
            case 3:
                acc.startup = parseInt(type.total_interactions);
                break;
            case 4:
                acc.export = parseInt(type.total_interactions);
                break;
            case 5:
                acc.training = parseInt(type.total_interactions);
                break;
            // Add cases for other interaction types as needed
            default:
                break;
        }
        return acc;
    }, initialTotals);
}

export function validateInteractionDate(currentDate: Date , interactionDate: Date | null, originalDate: Date | null = null) {
    const now = new Date(currentDate);
    const interaction = interactionDate ? new Date(interactionDate) : new Date(currentDate);

    console.log('now:', now);
    console.log('interaction date:', interaction);

    const fiscalYearStart = new Date(now.getFullYear(), 6, 1); // July 1 of current year
    const fiscalYearEnd = new Date(fiscalYearStart.getFullYear() + 1, 6, 1); // July 1 of next year (exclusive)
    const august1 = new Date(fiscalYearStart.getFullYear(), 7, 1); // August 1 of current fiscal year

    const prevFiscalYearStart = new Date(fiscalYearStart.getFullYear() - 1, 6, 1); // July 1 of previous year
    const prevFiscalYearEnd = new Date(fiscalYearStart); // July 1 of current year (exclusive)

    if (!originalDate) {
        // Handling new interactions (no original date provided)
        // Rule 1: Must be in the current fiscal year
        if (interaction >= fiscalYearStart && interaction < fiscalYearEnd) {
            return { valid: true, message: "" };
        }

        // Rule 2: Allow previous fiscal year only before August 1
        if (interaction >= prevFiscalYearStart && interaction < prevFiscalYearEnd && now <= august1) {
            return { valid: true, message: "" };
        }

        // Rule 3: Outside of allowed range
        return { valid: false, message: "The date must be within the current fiscal year, unless today is before August 1, in which case dates from the previous fiscal year are also allowed." };
    } else {
        // Handling edits to existing interactions (originalDate provided)
        const original = new Date(originalDate);

        // Determine the fiscal year range for the original date
        const originalFiscalYearStart =
            original >= fiscalYearStart ? fiscalYearStart : original >= prevFiscalYearStart ? prevFiscalYearStart : null;
        const originalFiscalYearEnd =
            original >= fiscalYearStart ? fiscalYearEnd : original >= prevFiscalYearStart ? prevFiscalYearEnd : null;

        if (!originalFiscalYearStart || !originalFiscalYearEnd) {
            return {
                valid: false,
                message: "Original date is outside the allowed fiscal years."
            };
        }

        // Rule 4: Allow movement within the fiscal year of the original date
        if (interaction >= originalFiscalYearStart && interaction < originalFiscalYearEnd) {
            return { valid: true, message: "Date is valid within the original fiscal year." };
        }

        // Rule 5: Lock previous fiscal year after August 1
        if (original >= prevFiscalYearStart && original < prevFiscalYearEnd && now > august1) {
            return {
                valid: false,
                message: "Cannot modify interactions in the previous fiscal year after August 1."
            };
        }

        // Rule 6: General restriction to stay within the fiscal year of the original date
        return {
            valid: false,
            message: "Date changes must remain within the fiscal year of the original interaction."
        };
    }
}